$black: #000000;
$white: #ffffff;
$orange: #FAB47C;

$gradient-rainbow: linear-gradient(90deg, #FAB47C 0%, #F63D68 32.29%, #FB459A 66.15%, #59A7E0 100%);
$gradient-rainbow-opacity: linear-gradient(90deg, rgba(250,180,124,0.5) 0%, rgba(246,61,104,0.5) 32.29%, rgba(251,69,154,0.5) 66.15%, rgba(89,167,224,0.5) 100%);
$gradient-overlay: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 50%);
$border-gradient-rainbow: linear-gradient(135deg, #F63D68 0%, #FAB47C 100%);
$bnt-box-shadow: 0px 0px 10px #F63D68;
$separator-gradient-rainbow: linear-gradient(90deg, rgba(246,61,104,0) 0%, rgba(246,61,104,1) 40%, rgba(250,180,124,1) 60%, rgba(250,180,124,0) 100%);

$separator-gradient: linear-gradient(270deg, rgba(246, 61, 104, 0) 0%, #F63D68 33.85%, #FAB47C 67.19%, rgba(250, 180, 124, 0) 100%);

$input-box-background: linear-gradient(270deg, rgba(255, 255, 255, 0.1) 73.67%, rgba(255, 255, 255, 0) 102.17%);

$text-box-background: linear-gradient(90.06deg, rgba(0, 0, 0, 0.8) 0.1%, rgba(89, 167, 224, 0.8) 123.07%, rgba(251, 69, 154, 0.8) 254.06%, rgba(246, 61, 104, 0.8) 385.05%, rgba(250, 180, 124, 0.8) 513.37%);
//$text-box-background-revert: linear-gradient(90.06deg, rgba(250, 180, 124, 0.8)  0.1%, rgba(246, 61, 104, 0.8) 123.07%, rgba(251, 69, 154, 0.8) 254.06%, rgba(89, 167, 224, 0.8)  385.05%,  rgba(0, 0, 0, 0.8)513.37%);
$text-box-background-revert: linear-gradient(270.06deg, rgba(0, 0, 0, 0.8) 0.1%, rgba(89, 167, 224, 0.8) 123.07%, rgba(251, 69, 154, 0.8) 254.06%, rgba(246, 61, 104, 0.8) 385.05%, rgba(250, 180, 124, 0.8) 513.37%);

