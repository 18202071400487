@font-face {
    font-family: 'Rubik-Light';
    src: url('./fonts/Rubik/Rubik-Light.ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik-Regular';
    src: url('./fonts/Rubik/Rubik-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik-Medium';
    src: url('./fonts/Rubik/Rubik-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik-SemiBold';
    src: url('./fonts/Rubik/Rubik-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik-Bold';
    src: url('./fonts/Rubik/Rubik-Bold.ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik-ExtraBold';
    src: url('./fonts/Rubik/Rubik-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'BebasNeue-Regular';
    src: url('./fonts/BebasNeue/BebasNeue-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


html,
body {
    font-family: 'Rubik-Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 25px;
    color: $white;
}

@mixin text($fontFamily, $size, $lineHeight, ) {
    font-family: $fontFamily;
    line-height: $lineHeight;
    font-size: $size;
    font-weight: normal;
}

/* h1 by page */

h1 {
    &.light-title-page {
        @include text('Rubik-Light', 24px, 28px);
    }

    &.medium-title-page {
        @include text('Rubik-Medium', 28px, 33px);
    }

    &.rainbow-title-page {
        @include text('BebasNeue-Regular', 40px, 48px);
        background: $gradient-rainbow;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    &.xl-title {
        @include text('BebasNeue-Regular', 80px, 96px);
    }
}


/* h2 by page */

h2 {
    
    &.second-title-page {
        @include text('Rubik-Regular', 16px, 19px);
    }

    &.second-title-page-large {
        @include text('Rubik-Regular', 20px, 24px);
    }

    &.second-title-regular-text {
        @include text('Rubik-Regular', 14px, 17px);
    }

    &.rainbow-title-page {
        @include text('BebasNeue-Regular', 45px, 54px);
        background: $gradient-rainbow;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

    }

    &.medium-text-large {
        @include text('Rubik-Medium', 20px, 24px);
    }
}
.underline {
    text-decoration: underline;
}

.grey {
    color: rgba(255, 255, 255, 0.6);
}

.white {
    color: #fff;
}
.rainbow {
    background: $gradient-rainbow;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.gradient {
    background: $border-gradient-rainbow;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.rainbow-outline {
    background: $gradient-rainbow;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 4px transparent;
    text-stroke: 4px transparent;
    color: #000;
}

/* standard by page */
h1,
h2,
h3,
h4,
p,
a,
label,
span {

    &.light-text {
        @include text('Rubik-Light', 16px, 19px);
    }
    
    &.light-text-small {
        @include text('Rubik-Light', 14px, 17px);
    }

    &.light-text-large {
        @include text('Rubik-Light', 24px, 29px);
    }

    &.light-text-very-small {
        @include text('Rubik-Light', 12px, 14px);
    }

    &.superlight-text-very-small {
        @include text('Rubik-Light', 10px, 12px);
    }

    &.regular-text {
        @include text('Rubik-Regular', 16px, 19px);
    }

    &.regular-text-small {
        @include text('Rubik-Regular', 14px, 17px);
    }

    &.bold-text {
        @include text('Rubik-Bold', 20px, 24px);
    }

    &.bold-text-small {
        @include text('Rubik-Bold', 16px, 19px);
    }

    &.medium-text-large {
        @include text('Rubik-Medium', 20px, 24px);
    }

    &.medium-text {
        @include text('Rubik-Medium', 16px, 19px);
    }

    &.medium-text-small {
        @include text('Rubik-Medium', 14px, 17px);
    }
    &.medium-text-xs {
        @include text('Rubik-Medium', 12px, 14px);
    }
}

a,
a:hover,
a:active {
    color: $white;
}