/* margin and padding */
$spaceamounts: (
    0,
    5,
    8,
    10,
    13,
    15,
    17,
    20,
    26,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    82
); // to include the pixel amounts you need.
$sides : (top, bottom, left, right);

@each $space in $spaceamounts {
    @each $side in $sides {
        .custom-m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .custom-p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}

@mixin rounded($border) {
    -webkit-border-radius: $border;
    -moz-border-radius: $border;
    border-radius: $border;
}

@mixin shadow($style) {
    -webkit-box-shadow: $style;
    -moz-box-shadow: $style;
    box-shadow: $style;
}

@mixin custom-bg($btn-background: "", $btn-shadow: "") {

    background: $btn-background;
    @include shadow($btn-shadow);

    &:hover {
        /*opacity: 0.8;*/
        @include shadow(none);

        &:before {
            background: $gradient-rainbow-opacity;
        }
    }

    &:disabled {
        /*opacity: 0.3;*/
        cursor: auto;
        @include shadow(none);

        &:before {
            background: $gradient-rainbow-opacity;
        }

        .btn-text {
            opacity: 0.5;
        }
    }
}

@mixin custom-button($button-size: '', $tyle: 'width-fixed', $rounded: 10px) {

    @include rounded($rounded);

    @if (str-index($tyle, 'width-')) {
        width: $button-size;
    }

    @else {
        max-width: $button-size;
        width: 90%;
    }

    display : inline-block;
    border : none;
    padding : 10px;
    cursor : pointer;

    @include custom-bg($black, $bnt-box-shadow);

    position: relative;
    box-sizing: border-box;

    $border: 2px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    @include rounded(10px);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: $gradient-rainbow // $border-gradient-rainbow;
    }

    // button text
    .btn-text {
        margin-bottom: 0px;
        text-align: center;
        text-decoration: none;
        @include text('Rubik-Medium', 16px, 19px);
        color: $white;
    }
}

@mixin icon-button($button-size: '', $tyle: 'width-fixed', $rounded: 8px) {

    @include rounded($rounded);

    @if (str-index($tyle, 'width-')) {
        width: $button-size;
        height: $button-size;
    }

    display : inline-block;
    border : none;
    cursor : pointer;

    @include custom-bg($black, $bnt-box-shadow);

    position: relative;
    box-sizing: border-box;

    $border: 2px;
    background: #000;
    background-clip: padding-box;
    border: solid $border transparent;
    @include rounded(8px);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: $border-gradient-rainbow // $border-gradient-rainbow;
    }

    img {
        max-width: 80%;
        margin: 0 auto;
        height: auto;
    }

    // button text
    .btn-text {
        margin-bottom: 0px;
        text-align: center;
        text-decoration: none;
        @include text('Rubik-Medium', 16px, 19px);
        color: $white;
    }
}

@mixin custom-black-button($button-size: '', $tyle: 'width-fixed', $rounded: 10px) {
    @include rounded($rounded);

    @if (str-index($tyle, 'width-')) {
        width: $button-size;
    }

    @else {
        max-width: $button-size;
        width: 90%;
    }

    display : inline-block;
    border : none;
    padding : 10px;
    cursor : pointer;
    position: relative;
    box-sizing: border-box;
    border:none;
    background-color: transparent;

    .btn-text {
        margin-bottom: 0px;
        text-align: center;
        text-decoration: none;
        @include text('Rubik-Medium', 16px, 19px);
        color: $white;
    }
}



/* general style */



.custom-btn {
    &.big-btn {
        @include custom-button(280px);
    }

    &.small-bnt {
        @include custom-button(200px, "not-fixed");
    }

    &.super-small-bnt {
        @include custom-button(150px, "not-fixed");
    }

    &.full-width-btn {
        @include custom-button(100%);
    }

    &.square-bnt {
        @include custom-button(35px, "not-fixed");
        height: 35px;
    }

    &.square-bnt-small {
        @include icon-button(30px, "not-fixed");
        height: 30px
    }

    &.w100 {
        width: 100%;
    }


}


.black-btn {
    &.super-small-bnt {
        @include custom-black-button(150px, "not-fixed");
    }
}


.link-btn {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    background: transparent;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    color: $white;

    .icon {
        width: 1rem;
        height: auto;
    }
}

.hide {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.separator {
    height: 2px;
    max-width: 334px;
    width: 100%;
    background: $separator-gradient;
    margin: 0 auto;
}

.arrow-up {
    transform: rotate(180deg);
}

.angle-sx {
    bottom: -14px;
    left: 0px;
}

.angle-dx {
    bottom: -14px;
    right: 0px;
}

.border-bottom-grey {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.rainbow-separator {
    width: 90%;
    margin: 10px auto;
    height: 1px;
    background: $separator-gradient-rainbow;
}

.confirmation {
    display: inline-block;
    background: linear-gradient(0deg, rgba(68, 68, 68, 0.69), rgba(68, 68, 68, 0.69));
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0px 6px;
}


html,
body,
#root {
    height: 100%;
    width: 100%;
}

.custom-scrollbar {
    .ps__rail-x {
        height: 8px;

        .ps__thumb-x {
            height: 4px;
            cursor: pointer;
        }

        &:hover {
            background-color: transparent !important;

            .ps__thumb-x {
                height: 4px;
            }
        }

        &:focus {
            background-color: transparent !important;

            .ps__thumb-x {
                height: 4px;
            }
        }

        &.ps--clicking {
            background-color: transparent !important;

            .ps__thumb-x {
                height: 4px;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.api-error {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
    background: #f63d68;
    padding: 10px;
    box-shadow: 0px 0px 10px #f63d68;
    border-radius: 10px;
    width: 85%;
    text-align: center;
    animation: fadeIn 1s;
}

html {
    background: $black;
}

body {
    display: flex;
    flex-direction: column;
    background: $black;
    height: auto;
    min-height: 100%;

    #root {
        z-index: 1;
        max-width: 500px; // setting
        margin: 0 auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .master-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }

    &.communityFeedList-page {

        /*  .master-container{
            min-height: 100%;

            .master-container-section, .container {
                min-height: calc(100vh - 65px - 60px - 100px); //fullH - headerH - footerH
            }
        }*/

        .header-container {

            .account,
            .title {
                display: block;
            }

            .close-section {
                display: none;
            }
        }

        .custom-btn {
            @include custom-button(280px);
        }

        .community-header {
            .img-container {
                width: 30px;
                height: 30px;

                img {
                    width: 100%;
                }
            }
        }

        .community-body {
            height: 180px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;


            .box-info {
                bottom: 16px;
            }
        }

    }

    &.wallet-page {

        #root {
            flex: 1;
            overflow: hidden;
        }

        .master-container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    &.communitySelectedFeed-page {

        .header-container {

            .account,
            .title {
                display: block;
            }

            .close-section {
                display: none;
            }
        }

        /* .master-container{
            min-height: 100%;

            .master-container-section, .container {
                min-height: calc(100vh - 65px - 60px); //fullH - headerH - footerH
            }
        }*/

        .description-title {
            width: 90%;
            margin: 0 auto;
        }

        .community-section {
            overflow: hidden;
        }

        .container-product {
            height: 150px;
            width: 200%;

            .block {
                width: 80px;
                height: 100%;
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
                @include rounded(5px);
                position: relative;

                .inner {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .romboblock {
                    background-position: top center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 50px;
                    width: 50px;
                    cursor: pointer;

                    img {
                        width: 25px;
                        height: 25px;
                        top: 13px;
                        left: 13px;
                        @include rounded(100%);
                        z-index: 1;
                    }
                }

                .soldout_overlay {
                    @include rounded(5px);
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    cursor: auto;

                    .soldout_overlay_bg {
                        @include rounded(5px);
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        opacity: 0.7;
                        background: linear-gradient(180deg, #F63D68 0%, #FAB47C 100%);

                    }

                    .text {
                        z-index: 2;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        display: inline-block;
                    }
                }
            }

            &.items1 {
                justify-content: center;
                width: 100%;
                margin: 0 auto !important;

                .block:last-child {
                    margin-right: 0 !important;
                }
            }

            &.items2 {
                justify-content: center;
                width: 100%;
                margin: 0 auto !important;

                .block:last-child {
                    margin-right: 0 !important;
                }
            }

            &.items3 {
                justify-content: center;
                width: 100%;
                margin: 0 auto !important;

                .block:last-child {
                    margin-right: 0 !important;
                }
            }

            &.items4 {
                @media (min-width:400px) {
                    justify-content: center;
                    width: 100%;
                    margin: 0 auto !important;

                    .block:last-child {
                        margin-right: 0 !important;
                    }
                }
            }

            &.items5 {
                @media (min-width:500px) {
                    justify-content: center;
                    width: 100%;
                    margin: 0 auto !important;

                    .block:last-child {
                        margin-right: 0 !important;
                    }
                }
            }
        }

        .container-tokens-wrapper {
            width: 100%;
            overflow-x: auto;

            position: relative;
        }

        .user-tokens {
            position:relative;
            height:70px;
        }

        .container-tokens {

            position: relative;
            flex-wrap: nowrap;
            width: fit-content;
            margin: 0 auto !important;



            .block {
                width: 60px;
                height: 100%;

                .romboblock {
                    background-position: top center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 60px;
                    width: 60px;
                    cursor: pointer;

                    img {
                        width: 30px;
                        height: 30px;
                        top: 15px;
                        left: 15px;
                        border-radius: 100%;
                        z-index: 1;
                    }

                    &.has-msg {
                        &:after {
                            content: " ";
                            display: block;
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            background: #F63D68;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 50%;
                            height: 12px;
                            width: 12px;
                            z-index: 1;

                        }
                    }
                }
            }
        }

        .hands-wrapper {
            width: 100%;
            max-width: 330px;
            margin: 0 auto;
            height: 120px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        .public-chat-container {
            bottom: 0px;
            right: 0px;

            .customer {
                margin-left: -3px;
                margin-bottom: 3px;
            }
        }

    }


    &.communityTokensList-page {

        /* .master-container{
            min-height: 100%;

            .master-container-section, .container {
                min-height: calc(100vh - 25px - 60px); //fullH - headerH - footerH
            }
        }*/

        .container-list {
            max-width: 400px;
            margin: 0 auto;

            .custom-btn {
                float: left;
            }

            .icon-currency {
                width: 15px;
                height: 15px;
            }

            .isOdd .block {
                direction: rtl;
            }

            .isOdd {
                .romboblock {
                    margin: 0px auto;
                }
            }

            .romboblock {
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100px;
                width: 100px;


                img {
                    width: 50px;
                    height: 50px;
                    top: 25px;
                    left: 25px;
                    @include rounded(100%);
                }
            }
        }
    }

    &.communityTokenDetails-page {

        /*  .master-container{
            min-height: 100%;

            .master-container-section, .container {
                min-height: calc(100vh - 25px - 60px); //fullH - headerH - footerH
            }
        }*/

        .romboblock {
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100px;
            width: 100px;

            img {
                width: 50px;
                height: 50px;
                top: 25px;
                left: 25px;
                @include rounded(100%);
            }
        }

        .tokendetail {
            background: $input-box-background;
            border-radius: 5px;
            overflow: hidden;
            width: 100%;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;

            .numberBox {
                color: $white;
                opacity: 0.4;
            }


            .tokens-available {
                left: 0;
                top: 50%;
                transform: translate(-130%, -50%);

            }

        }

        .token-counter-bar {
            background: $input-box-background;
            border-radius: 5px;
            overflow: hidden;
            width: 100%;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            height: 6px;

            .background-block {
                background: $gradient-rainbow;
                position: absolute;
                right: left;
                border-radius: 5px;
                top: 0;
                height: 100%;

            }
        }

        .container-description {

            max-width: 400px;
            margin: 0 auto;

            .image-clip {
                background-repeat: no-repeat;
                background-size: contain;
                height: 100%;
                width: 103px;

                &.icon-clip-open {
                    background-position: top left;
                    left: 0px;
                    top: 0px;
                }

                &.icon-clip-close {
                    background-position: top right;
                    right: 0px;
                    top: 0px;
                }
            }
        }
    }

    &.onboarding-page {
        .header-container {
            display: none !important;
        }

        .master-container-section {
            padding-top: 26px;
            /*compensate lack of header*/
        }

        .info-btn {
            padding: 0;
            border: none;
            box-shadow: none;
            appearance: none;
            font-size: 0;
            background: transparent;
            cursor: pointer;
            height: 15px;
            width: 15px;
            display: inline-block;
            vertical-align: top;
        }




    }

    &.authenticate-page {
        .header-container {
            display: none !important;
        }

        .master-container-section {
            padding-top: 26px;
            /*compensate lack of header*/
        }

        .logo-wrapper {
            width: 80px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .lottie-rail-wrapper {
            text-align: center;

            img {
                margin: 0 auto;
            }
        }
    }

    &.checkout-page {
        .header-container {
            display: none !important;
        }

        .master-container-section {
            padding-top: 26px;
            /*compensate lack of header*/
            display: flex;
            position: relative;
            flex-direction: column;
        }

        .item-thumbnail {
            height: 50px;
            width: 50px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &.success-page {
        .header-container {
            display: none !important;
        }

        .master-container {
            position: relative;
        }

        .master-container-section {
            padding-top: 26px;
            /*compensate lack of header*/
            display: flex;
            flex-direction: column;

            .container {
                height: 50vh;
            }
        }

        .flower-bg {
            background-size: cover;
            width: 100%;
            height: 50vh;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background-position: top;
        }
    }

    &.profile-page {
        .header-container {
            .account {
                display: block;
            }

            .icon-section {
                min-height: 28px;
            }

            .close-section {
                display: none !important;
            }
        }


        .master-container-section {

            /*compensate lack of header*/
            display: flex;
            flex-direction: column;

            .absolute-text {
                width: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
            }

            .account-icon {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                position: relative;
                box-sizing: border-box;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

        }

        section {
            width: 100%;
            position: relative;
        }

        .nfts-list {
            position: relative;
            flex-wrap: nowrap;
            width: fit-content;

            .item {
                height: 130px;
                width: 130px;
                border-radius: 5px;
                position: relative;
                margin-right: 10px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                .nftbalancewrapper {
                    position: absolute;
                    top: -5px;
                    right: -5px;

                    .nftbalance {
                        display: inline-block;
                        position: relative;
                        @include rounded(5px);
                        box-sizing: border-box;
                        $border: 2px;
                        background: $black;
                        background-clip: padding-box;
                        border: solid $border transparent;
                        width: 29px;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: -1;
                            margin: -$border;
                            border-radius: inherit;
                            background: $gradient-rainbow // $border-gradient-rainbow;
                        }
                    }
                }

                &:last-of-type {
                    margin-right: none !important;
                }
            }

            &.is-placeholder {
                width: 100%;
                overflow: hidden;

                .item {
                    background: rgba(255, 255, 255, 0.05);
                    border-radius: 5px;
                }

                >p {
                    top: 50%;
                    transform: translateY(-50%);
                }

                .btn-wrapper {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .private-channels-list {
            position: relative;
            flex-wrap: nowrap;
            width: fit-content;


            .item {
                height: 180px;
                width: 270px;
                border-radius: 5px;
                margin-right: 10px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;


                .box-info {
                    bottom: 0;
                    left: 0;
                }

                .romboblock-wrapper {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    .romboblock {
                        background-position: top center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 60px;
                        width: 60px;
                        cursor: pointer;

                        img {
                            width: 30px;
                            height: 30px;
                            top: 15px;
                            left: 15px;
                            border-radius: 100%;
                            z-index: 1;
                        }
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: $gradient-overlay;
                }

                &:last-of-type {
                    margin-right: none !important;
                }
            }

            &.is-placeholder {
                width: 100%;
                overflow: hidden;

                .item {
                    background: rgba(255, 255, 255, 0.05);
                    border-radius: 5px;
                }

                >p {
                    top: 50%;
                    transform: translateY(-50%);
                }

                .btn-wrapper {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .currency-icon {
            width: 25px;
            height: auto;
        }

        .currency-icon-mini {
            width: 15px;
            height: auto;
            display: inline-block;
            vertical-align: middle;
        }

    }

    &.deleteaccount-page {
        .master-container-section {
            /*compensate lack of header*/
            display: flex;
            flex-direction: column;
        }
    }

    &.communityChat-page {
        height: 95vh;

        .header-container {

            .account,
            .title {
                display: block;
            }

            .close-section {
                display: none;
            }
        }

        .master-container-section {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .chat-container {
            .scrollbottom-wrapper {
                z-index: 100;
                bottom: 15px;
                right: 25px;
            }

            $chatheight: calc(95vh - 460px);
            /* 460 = sum of heights of non chat wrapper elements */

            height: $chatheight;


            margin-bottom: 10px;

            overflow: hidden;
            position: relative;

            .custom-scrollbar {
                >div:first-child {
                    overflow-x: hidden !important;
                }
            }

            .chat-messages-wrapper {
                min-height: 100%;
                padding-top: 10px;
                padding-left: 10px;
                padding-right: 10px;

                .chat-message-element {
                    max-width: 375px;

                    .user-profile-icon {
                        width: 20px;
                        height: 20px;
                        border-radius: 5px;
                    }

                    .chat-bubble {
                        background-color: rgba(255, 255, 255, 0.15);
                        padding: 10px;
                        border-radius: 5px 5px 5px 0;
                    }

                    .like-element {
                        border-left: 1px solid;
                    }

                    &.currentUser {
                        margin-left: auto;
                    }
                }
            }

            .last-element-scrollbar {
                height: 30px;
            }
        }

        .scrollbottom-btn {
            left: 0px;
            bottom: 0px;

            z-index: 999999999;

            .custom-btn {
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        .textareaChat {
            bottom: 0;
            left: 0;

            .text-area-container {
                width: 90%;
                margin: 0 auto;
            }

            .line {
                width: 35px;
                background: linear-gradient(275.62deg, #FAB47C 8.96%, rgba(250, 180, 124, 0) 100%);
                height: 1px;

                &.dx {
                    transform: rotate(-180deg);
                }
            }

            p {
                color: $orange;
            }

            input[type=textarea] {
                background: $black;
                color: $white;
                appearance: none;
                border: 1px solid rgba(255, 255, 255, 0.4);
                border-radius: 18px;
                padding: 8px 20px;
            }
        }
    }

    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .popup-inner {
            width: 270px;
            padding: 20px 15px;
            display: inline-block;
            position: relative;
            @include rounded(10px);
            box-sizing: border-box;
            $border: 2px;
            background: rgba(25, 25, 25, 1);
            background-clip: padding-box;
            border: solid $border transparent;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -$border;
                border-radius: inherit;
                background: $gradient-rainbow // $border-gradient-rainbow;
            }

            .popup-content {
                z-index: 1;
                display: inline-block;
                position: relative;
            }

        }

    }

    .master-container {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        .master-container-section {
            flex-grow: 1;
        }
    }

    .lottie-rail-wrapper {
        position: absolute;
        z-index: -1;
        bottom: 0;
        width: 90%;
        max-width: 500px;
        left: 50%;
        transform: translateX(-50%);
    }

}



.header-container {


    .account,
    .title {
        display: none;
    }

    .close-section {
        display: block;
        right: 0px;
    }

    .account {
        left: 0px;
    }

    .border-gradient {
        width: 35px;
        height: 35px;

        position: relative;
        box-sizing: border-box;

        $border: 2px;
        background: #000;
        background-clip: padding-box;
        border: solid $border transparent;
        @include rounded(5px);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
            background: $border-gradient-rainbow;
        }
    }

    .logo-title-page {
        width: 35px;
        height: 35px;
        @include rounded(5px);
    }
}

.footer-container {
    /* style to centered full height layout */
    margin-top: auto;
    position: relative;

    .footer-block {

        .inner-footer {
            width: 100%;
            margin-right: auto;
            margin-left: auto;

            .privacy {
                padding: 0 10px;
                border-right: 1px solid $black;
                border-left: 1px solid $black;
                margin: 0 10px;
            }

            div {
                display: flex;
            }

            .copyright {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: right;
            }
        }
    }
}

.debug {
    position: fixed;
    z-index: 999999999999999;
    width: calc(100% - 200px);
    background-color: brown;
    color: #FFF;
    font-family: monospace;
    max-height: calc(100vh - 200px);
    left: 0;
    top: 50px;
}


@media screen and (orientation:landscape) and (min-width:1024px) {
    body {
        background-image: url(../img/frame_bg.svg);
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        height: auto;

        #root {
            padding: 0 20px;
        }
    }
}


/*cookiebot*/
div#CybotCookiebotDialog {
    position: fixed;
    top: auto !important;
    bottom: 0;
    transform: none !important;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink,
#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentControls {
    font-weight: 300 !important;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 1.6;
}

div#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContent {
    border-bottom: #fff 1px solid;
}

div#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentTextOverview {
    width: 100%;
}

div#CybotCookiebotDialog #CybotCookiebotDialogBodyButtonAccept {
    width: 191px !important;
    color: #fff;
    font-weight: 400 !important;
    font-family: 'Rubik', sans-serif;
    padding: 4px 20px !important;
    height: auto;
    border-radius: 20px;
    background-image: url(../img/ctaCookie.png);
    background-size: 65%;
    background-position: center;
    background-repeat: no-repeat;
}

#CybotCookiebotDialogDetailBodyContent {
    border-bottom: 1px solid #ffffff;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
    font-weight: 700 !important;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 15px;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBody,
#CybotCookiebotDialog div#CybotCookiebotDialogBody {
    max-width: inherit;
    padding: 10px 200px 0 !important;
    box-sizing: border-box;
}

@media screen and (max-width:1600px) {

    #CybotCookiebotDialog #CybotCookiebotDialogDetailBody,
    #CybotCookiebotDialog div#CybotCookiebotDialogBody {
        padding: 10px 85px 0 !important;
    }
}

@media screen and (max-width:900px) {

    #CybotCookiebotDialog #CybotCookiebotDialogDetailBody,
    #CybotCookiebotDialog div#CybotCookiebotDialogBody {
        padding: 10px 25px 0 !important;
    }

}